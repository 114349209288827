<template>
  <div>
    <div class="event-markets-group-item">
      <div
        class="event-markets-group-item-selections-wrapper"
        v-for="selection in marketSelections"
        :key="selection.selectionId"
      >
        <div class="event-markets-group-item-selection-row">
          <div
            class="event-markets-group-item-selection"
            @click="toggleBookmakers(market.marketCode, selection.selectionType.selectionCode)"
          >
            <div class="selection-icon">
              <ChevronRightIcon v-if="!toggledBookmakers[`${market.marketCode}_${selection.selectionType.selectionCode}`]" />
              <ChevronDownIcon v-else />
            </div>
            <span v-if="!remapSelectionCode(selection).twoPartLabel">{{ remapSelectionCode(selection) }} <span class="market-line">{{ displayLine(selection) }}</span></span>
            <div
              v-else
              class="two-part-selection-code"
            >
              <span class="left-side">{{ remapSelectionCode(selection).leftSide }}</span>
              <span class="divider">/</span>
              <span class="right-side">{{ remapSelectionCode(selection).rightSide }}</span>
            </div>
          </div>
          <div class="event-markets-group-item-selection-price">
            <div class="logo">
              <img
                :src="bookmakers[0].logo"
                :alt="bookmakers[0].name"
              >
            </div>
            <EventMarketSelectionPrice
              :market="market"
              :betslip-market-name="betslipMarketName"
              :selection="selection"
              :event-id="eventId"
              :selection-name="remapSelectionCode(selection)"
              :has-sgp="hasSgp"
              :source="marketsInternalSource"
            />
          </div>
        </div>
        <div
          class="bookmakers-selections-wrapper"
          v-if="toggledBookmakers[`${market.marketCode}_${selection.selectionType.selectionCode}`]"
        >
          <div
            class="bookmaker-selection"
            v-for="(bookmaker, index) in outsideBookmakers"
            :key="index"
          >
            <div class="logo">
              <img
                :src="bookmaker.logo"
                :alt="bookmaker.name"
              >
            </div>
            <EventMarketSelectionPrice
              :market="bookmaker.markets[0] || {}"
              :betslip-market-name="betslipMarketName"
              :source="bookmaker.name"
              :selection="selection"
              :event-id="eventId"
              :selection-name="remapSelectionCode(selection)"
              :has-sgp="hasSgp"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  filter, replace, includes,
  toLower, split, isNil, map,
} from 'lodash';
import {
  ChevronDownIcon, ChevronRightIcon,
} from '@/components/svg';
import MarketMapper from '@/services/helpers/market-mapper';
import EventMarketSelectionPrice from './EventMarketSelectionPrice';

export default {
  components: {
    ChevronRightIcon,
    ChevronDownIcon,
    EventMarketSelectionPrice,
  },
  props: {
    market: {
      type: Object,
      required: true,
    },
    betslipMarketName: {
      type: String,
      required: true,
    },
    bookmakers: {
      type: Array,
      required: true,
      default: () => [],
    },
    teams: {
      type: Object,
      default: null,
    },
    eventId: {
      type: String,
      default: '',
    },
    sportLabel: {
      type: String,
      default: '',
    },
    hasSgp: {
      type: Boolean,
      default: false,
    },
    marketsInternalSource: {
      type: String,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const homeTeam = computed(() => props.teams?.home);
    const awayTeam = computed(() => props.teams?.away);

    const marketSelections = computed(() => MarketMapper.sortSelections({ selections: props.market.selections, isUsaView: props.isUsaView }));

    const setTeamNames = (selectionLabel) => {
      let replacedLabel = toLower(selectionLabel);
      if (includes(replacedLabel, 'home') && replacedLabel !== 'home run') {
        replacedLabel = replace(replacedLabel, /home/g, homeTeam.value.name);
      }
      if (includes(replacedLabel, 'away')) {
        replacedLabel = replace(replacedLabel, /away/g, awayTeam.value.name);
      }
      if (includes(replacedLabel, '/')) {
        const labelSplited = split(replacedLabel, '/');
        replacedLabel = {
          twoPartLabel: true,
          leftSide: labelSplited[0],
          rightSide: labelSplited[1],
        };
      }
      return replacedLabel;
    };
    const remapSelectionCode = (selection) => {
      const selectionName = MarketMapper.selectionName(selection, { sportLabel: props.sportLabel }, props.isUsaView);
      return setTeamNames(selectionName);
    };

    const checkMarketParamsEquality = (firstMarketParam, secondMarketParam) => (
      firstMarketParam.LINE === secondMarketParam.LINE
      && firstMarketParam.PERIOD === secondMarketParam.PERIOD
      && firstMarketParam.HALF === secondMarketParam.HALF
      && firstMarketParam.TEAM === secondMarketParam.TEAM
      && firstMarketParam.RACE_TO === secondMarketParam.RACE_TO
      && firstMarketParam.BIN_MINUTE_START === secondMarketParam.BIN_MINUTE_START
      && firstMarketParam.BIN_MINUTE_END === secondMarketParam.BIN_MINUTE_END
      && firstMarketParam.PLAY === secondMarketParam.PLAY
      && firstMarketParam.PUNT === secondMarketParam.PUNT
      && firstMarketParam.FIELD_GOAL === secondMarketParam.FIELD_GOAL
      && firstMarketParam.SELECTIONS === secondMarketParam.SELECTIONS
      && firstMarketParam.PLAYER_INDEX === secondMarketParam.PLAYER_INDEX
      && firstMarketParam.RESULT_AFTER_X === secondMarketParam.RESULT_AFTER_X
      && firstMarketParam.PITCH_INDEX === secondMarketParam.PITCH_INDEX
      && firstMarketParam.PLATE_APPEARANCE === secondMarketParam.PLATE_APPEARANCE
      && firstMarketParam.BATTER_PLAYER_INDEX === secondMarketParam.BATTER_PLAYER_INDEX
      && firstMarketParam.PITCHER_PLAYER_INDEX === secondMarketParam.PITCHER_PLAYER_INDEX
      && firstMarketParam.IS_TOP_INNING === secondMarketParam.IS_TOP_INNING
      && firstMarketParam.TEAM_SCORE_AT_CREATION === secondMarketParam.TEAM_SCORE_AT_CREATION
      && firstMarketParam.HOME_SCORE_AT_CREATION === secondMarketParam.HOME_SCORE_AT_CREATION
      && firstMarketParam.AWAY_SCORE_AT_CREATION === secondMarketParam.AWAY_SCORE_AT_CREATION
      && firstMarketParam.MILESTONE_YARDS === secondMarketParam.MILESTONE_YARD
      && firstMarketParam.MILESTONE === secondMarketParam.MILESTONE
      && firstMarketParam.DRIVE_INDEX === secondMarketParam.DRIVE_INDEX
      && firstMarketParam.SCORE_INDEX === secondMarketParam.SCORE_INDEX
      && firstMarketParam.POSSESSION === secondMarketParam.POSSESSION
      && firstMarketParam.YARD_LINE === secondMarketParam.YARD_LINE
    );

    const outsideBookmakers = computed(() => map(filter(props.bookmakers, (bookmaker) => bookmaker.name !== 'INTERNAL_AGGREGATION'),
      (bookmaker) => ({
        ...bookmaker,
        markets: filter(bookmaker.markets,
          (market) => checkMarketParamsEquality(market.marketType.params, props.market.marketType.params)),
      })));

    const toggledBookmakers = ref({});

    const toggleBookmakers = (marketCode, selectionCode, groupId) => {
      const groupIdString = groupId ? `_${groupId}` : '';
      if (!toggledBookmakers.value[`${marketCode}_${selectionCode}${groupIdString}`]) {
        toggledBookmakers.value[`${marketCode}_${selectionCode}${groupIdString}`] = true;
        return;
      }
      toggledBookmakers.value[`${marketCode}_${selectionCode}${groupIdString}`] = false;
    };

    const displayLine = (selection) => {
      const line = props.market.marketType.params.LINE;
      if (isNil(line)) return '';
      if (line === 0) return 0;
      if (!includes(props.market.marketType.marketCode, 'HANDICAP')) return line;
      let lineValue = '';
      lineValue = line;
      if (selection.selectionType.selectionCode === 'HOME' && line > 0) {
        lineValue = `+${line}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line < 0) {
        lineValue = `+${Math.abs(line)}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line > 0) {
        lineValue = `-${line}`;
      }
      return lineValue;
    };

    return {
      setTeamNames,
      remapSelectionCode,
      outsideBookmakers,
      toggleBookmakers,
      toggledBookmakers,
      includes,
      displayLine,
      marketSelections,
    };
  },
};
</script>

<style lang="scss">

.event-market-line-slider-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;

  .selected-line-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;

    span {
      font-weight: 600;
      margin-left: 8px;
    }
  }

  .slider-row {
    display: flex;
    width: 100%;
    height: 44px;
    align-items: center;

    .slider-button {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.event-markets-group-item-label {
  font-weight: 700;
  margin: 16px 0;
  color: #003C3C;
}

.event-markets-group-item {
  display: flex;
  flex-direction: column;
  width: 100%;

  .event-markets-group-item-selections-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    flex-direction: column;

    .event-markets-group-item-selection-row {
        display: flex;
        height: 44px;
        width: 100%;
        align-items: center;
        justify-content: space-between;

      .event-markets-group-item-selection {
        display: flex;
        height: 100%;
        width: 50%;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;

        .selection-icon {
          height: 24px;
          width: 24px;
          margin-right: 4px;
        }

        .two-part-selection-code {
          width: 95%;
          display: flex;
          align-items: center;

          .left-side, .right-side {
            display: inline-block;
            max-width: 45%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .divider {
            margin: 0 3px;
          }
        }

        .market-line {
          font-weight: 600;
        }
      }

      .event-markets-group-item-selection-price {
        display: flex;
        height: 44px;
        align-items: center;

        .logo {
          height: 44px;
          width: 44px;
          border-radius: 4px;
          overflow: hidden;
          border: 1px solid #E5EBEB;
        }

        .price {
          width: 170px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #003C3C;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 400;
          color: #191414;
          margin-left: 4px;

          &.added-to-betslip {
            border: 1px solid #003C3C;
          }

          &.internal {
            cursor: pointer;
          }
        }
      }
    }

    .bookmakers-selections-wrapper {
      display: flex;
      width: 100%;
      padding-top: 12px;
      margin-top: 12px;
      flex-wrap: wrap;
      justify-content: space-between;
      border-top: 1px solid #F0F0F0;

      .bookmaker-selection {
        display: flex;
        height: 44px;
        align-items: center;
        flex-basis: 31.2%;
        margin-bottom: 4px;

        .logo {
          height: 44px;
          width: 44px;
          min-width: 44px;
          background: #FFFFFF;
          border-radius: 4px;
          overflow: hidden;
          border: 1px solid #E5EBEB;
        }

        .price {
          width: 100%;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #003C3C;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 400;
          color: #191414;
          margin-left: 4px;
        }
      }
    }
  }
}

.label-spread {
  margin-right: 4px;
}

@media screen and (max-width: $maxMobileBreakpoint) {
  .event-markets-group-item {
    .event-markets-group-item-selections-wrapper {
      .event-markets-group-item-selection-row {
        .event-markets-group-item-selection {
          max-width: 50%;
        }
        .event-markets-group-item-selection-price {
          .price {
            width: 123px;
            font-size: 14px;
          }
        }
      }

      .bookmakers-selections-wrapper {
        .bookmaker-selection {
          flex-basis: 48%;

          .selection-price-value {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
